<template>
  <!-- Start Footer Style Two  -->
  <div class="pt--50 pb--120 test">
    <div class="wrapper plr--50 plr_sm--20">
      <v-row>
        <v-col lg="3" md="3" sm="12" cols="12">
          <div class="inner smImage" style="float: left">
            <div class="logo text-sm-left mb_sm--20 pb--10 ml--50 ml_md--30 ml_sm--5">
              <router-link to="/"
                ><v-img class="ml_sm--30" max-width="250" :src="logo" alt="Logo images"
              /></router-link>
            </div>
          </div>
        </v-col>
        <v-col lg="2" md="2" sm="6" xs="6" cols="6">
          <div class="inner text-left pt--15">
            <ul class="liststyle">
              <li v-for="(footerLinks, i) in footerLinks" :key="i">
                <!-- <a
                  :href="footerLinks.url"
                  style="font-family: PFBagueSansPro-Bold !important;font-size: 14px !important;"
                  ><p style="font-size:13px;color: white; opacity: 1.75">
                    <b>{{ footerLinks.name }}</b>
                  </p></a
                > -->
                <router-link
                  :to="footerLinks.url"
                  style="font-family: PFBagueSansPro-Bold !important;font-size: 14px !important;"
                  ><p style="font-size:13px;color: white; opacity: 1.75">
                    <b>{{ footerLinks.name }}</b>
                  </p></router-link
                >
              </li>
            </ul>
          </div>
        </v-col>
        <v-col lg="2" md="2" sm="6" xs="6" cols="6">
          <div class="inner text-left pt--15">
            <ul class="liststyle">
              <li v-for="(footerLinksNext, i) in footerLinksNext" :key="i">
                <!-- <a
                  :href="footerLinksNext.url"
                  style="font-family: PFBagueSansPro-Bold !important;font-size: 14px !important;"
                  ><p style="font-size:13px;color: white; opacity: 1.75">
                    <b>{{ footerLinksNext.name }}</b>
                  </p></a
                > -->
                <router-link
                  :to="footerLinksNext.url"
                  style="font-family: PFBagueSansPro-Bold !important;font-size: 14px !important;"
                  ><p style="font-size:13px;color: white; opacity: 1.75">
                    <b>{{ footerLinksNext.name }}</b>
                  </p></router-link
                >
              </li>
            </ul>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-img
      style="z-index: 1000 !important"
      class="imgBottom"
      :src="footer"
      alt="Logo images"
    />
  </div>
  <!-- End Footer Style Two  -->
</template>

<script>
export default {
  data() {
    return {
      logo: require("../../assets/images/home/health_footer.png"),
      footer: require("../../assets/images/bg/eikastiko_FOOTER.png"),
      socialList: [
        {
          icon: "fa-facebook-f",
          url: "https://www.facebook.com/",
        },
        {
          icon: "fa-linkedin-in",
          url: "https://www.linkedin.com/",
        },
        {
          icon: "fa-twitter",
          url: "https://twitter.com/",
        },
        {
          icon: "fa-instagram-square",
          url: "https://www.instagram.com/",
        },
      ],
      footerLinks: [
        {
          name: "Αρχική",
          url: "/",
        },
        {
          name: "Προνόμια",
          url: "/privileges",
        },
        {
          name: "Πληροφορίες",
          url: "/information",
        },
        {
          name: "Συχνές ερωτήσεις",
          url: "/questions",
        },
      ],
      footerLinksNext: [
        {
          name: "Όροι χρήσης",
          url: "/terms",
        },
        {
          name: "Πολιτική απορρήτου",
          url: "/privacy-policy",
        },
        {
          name: "Επικοινωνία",
          url: "/contact",
        },
      ],
    };
  },
  methods: {
    getFooterImage() {
      return this.footer;
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 959px) {
  .smImage {
    margin-left: -20px;
  }
}
.test {
  /* background: 
   linear-gradient(
    to right,
    #cc6672,
    #a57584,
    #6e7d9d
  ); */
  background: #ea222d;
}

.imgBottom {
  position: absolute;
  left: 0px;
  bottom: 2px;
}
</style>
