import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(Vuex)
Vue.use(VueAxios, axios)

export default new Vuex.Store({
    state: () => ({
        user : false,
        selectedTab : 'white',
        selectedInformation : 'white',
        systemNotifications : null,
        companyStores : null,
        transactions : null,
        token: null
    }),
    getters: {
        user: state => state.user,
        selectedTab: state => state.selectedTab,
        selectedInformation: state => state.selectedInformation,
        systemNotifications: state => state.systemNotifications,
        companyStores: state => state.companyStores,
        transactions: state => state.transactions,
        token: state => state.token,
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload;
        },
        setSelectedTab(state, payload) {
            state.selectedTab = payload;
        },
        setSelectedInformation(state, payload) {
            state.selectedInformation = payload;
        },
        setSystemNotifications(state, payload) {
            state.systemNotifications = payload;
        },
        setToken(state, payload) {
            state.token = payload;
        },
        setTransactions(state, payload) {
            state.transactions = payload;
        },
        setCompanyStores(state, payload) {
            const keys_to_keep = ['id', 'coordinates', 'name', 'address', 'primary_phone'];
            const result = payload.map(e => {
                const obj = {};
                keys_to_keep.forEach(k => obj[k] = e[k])
                return obj;
              });
            const resultArray = result.map(elm => 
                ({ id: elm.id, latlng: latLng(elm.coordinates.lat, elm.coordinates.lon), text: elm.name + '<br>' + elm.address + '<br>' + elm.primary_phone }));
            state.companyStores = resultArray;
        },
    },
    actions: {    
        async getHistory ({ commit }) {
            let response = await axios.get('external/me/notifications')
            if(response.data.status === "success" && response.data.data.user_notifications){
                commit("setHistory", response.data.data.user_notifications);
            }
                            
        },
        async getToken ({ commit }, token) {
            console.log(token)
            commit("setToken", token);
                            
        },
        async getTransactions ({ commit, getters }, page) {

            let response = await axios.get('external/me/transactions?page=' + page)
            if(response.data.status === "success" && response.data.data){
                commit("setTransactions", response.data.data);
            }
                            
        },
        async getUser ({ commit }) {
            try {
                let response = await axios.get('external/me',{
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    }
                })
                 if(response.data.status === "success" && response.data.data){
                    commit("setUser", response.data.data);
                } 
                return response.data.data;

            } catch (ex) {
                commit("setUser", null);               
            }
                    
        },
        async getCompanyStores ({ commit }) {
            let response = await axios.get('external/company/stores')
            commit("setCompanyStores", response.data.data.stores);                            
        },
        async getSystemNotifications ({ commit }) {
            let response = await axios.get('common/system-notifications')
            commit("setSystemNotifications", response.data.data);
                            
        },
    },
});
