<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    @click:outside="dialog = false"
  >
    <template v-slot:activator="{ on, attrs }">
      <span v-if="fromHeader === 'true'">
        <v-btn
          link
          :ripple="false"
          v-bind="attrs"
          v-on="on"
          text
          class="scrollactive-item"
          >Σύνδεση</v-btn
        >
      </span>
      <span v-else-if="fromHeaderMobile === 'true'">
        <v-list-item-title v-bind="attrs" v-on="on">Σύνδεση</v-list-item-title>
      </span>
      <span v-else>
        <v-hover v-slot="{ hover }">
          <v-btn
            :ripple="false"
            v-bind="attrs"
            v-on="on"
            text
            :elevation="hover ? 12 : 2"
            :class="{ 'on-hover': hover }"
            class="pl--40 mt--30 pl_sm--15 pr_sm--15 pr--40 pb--20 pt--20 addHover"
            style="font-family: 'PFBagueSansPro-Bold'; font-size: 18px; letter-spacing: 0px;"
            outlined
            :color="hover ? 'black' : 'white'"
            rounded
          >
            Σύνδεση
          </v-btn>
        </v-hover>
      </span>
    </template>
    <v-card>
      <v-form ref="form" v-model="valid" lazy-validation>
        <h3 style="text-align: center; padding: 5%">Σύνδεση</h3>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Κινητό*"
                v-model="mobile"
                :rules="mobileRules"
                type="number"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Κωδικός*"
                type="password"
                :rules="passwordRules"
                v-model="password"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <small class="mt--20">*Υποχρεωτικά πεδία</small>
            </v-col>
            <v-col cols="12">
              <p class="error" v-if="errors">{{ errors }}</p>
            </v-col>
          </v-row>
        </v-card-text>
        <div class="mb-2 col-md-8 mt--20">
          <a color="#ea222d" text @click="dialog = false">
            <ForgotPassword
              style="font-family: 'PFBagueSansPro-Bold'; font-size: 18px; letter-spacing: 0px;"
              title="Ξέχασα τον κωδικό μου;"
              icon="mdi-plus"
            />
          </a>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#ea222d"
            style="font-family: 'PFBagueSansPro-Bold'; font-size: 18px; letter-spacing: 0px;"
            text
            @click="dialog = false"
          >
            Κλείσιμο
          </v-btn>
          <v-btn
            color="#ea222d"
            style="font-family: 'PFBagueSansPro-Bold'; font-size: 18px; letter-spacing: 0px;"
            text
            @click="login"
          >
            Σύνδεση
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { asyncLoading } from "vuejs-loading-plugin";
import ForgotPassword from "@/views/ForgotPassword";
import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
  name: "Login",
  components: {
    ForgotPassword,
  },
  props: {
    fromHeader: String,
    fromHeaderMobile: String,
  },
  data() {
    return {
      errors: "",
      valid: true,
      dialog: false,
      mobile: "",
      password: "",
      mobileRules: [
        (v) => !!v || "Το κινητό τηλέφωνο είναι υποχρεωτικό",
        (v) =>
          /^\d+$/.test(v) ||
          "Το κινητό τηλέφωνο πρέπει να περιέχει μόνο αριθμούς",
        (v) =>
          (v && ("" + v).length == 10) ||
          "Το κινητό τηλέφωνο πρέπει να αποτελείται απο 10 αριθμούς",
      ],
      passwordRules: [(v) => !!v || "Ο κωδικός είναι υποχρεωτικός"],
    };
  },
  methods: {
    async login(e) {
      try {
        if (this.$refs.form.validate()) {
          this.$loading(true);
          e.preventDefault();
          this.errors = "";
          console.log("testsetse");

          axios
            .post("external/users/login", {
              mobile: this.mobile,
              password: this.password,
            })
            .then((response) => {
              console.log("meraaaa");
              // this.getToken(response.data.access_token)
              localStorage.setItem("token", response.data.access_token);
              this.$refs.form.reset();
              this.$router.go(this.$router.currentRoute);
            })
            .catch((error) => {
              this.$loading(false);
              console.log(error.response.data.message)
              if(/[Uu]ser.*[Nn]ot\s([fF]ound|[eE]xist)/g.test(error.response.data.message)){
                this.errors="Δεν βρέθηκε ο χρήστης. Παρακαλώ εγγραφείτε.";
              } else if (error.response.data.status == 401) {
                this.errors="Παρακαλώ ελέγξτε τα στοιχεία σας.";
              } else {
                this.errors="Something went wrong, please refresh and try again.";
              }
            });
        } else {
          return false;
        }
      } catch (e) {
        this.error = e.message;
        return e;
      }
    },
  },
};
</script>
<style scoped>
.v-btn--outlined {
  border: thin solid #ffff !important;
}
.v-btn {
  text-transform: none;
  font-family: "PFBagueSansPro-Bold" !important;
}
.v-card__actions > .v-btn.v-btn {
  padding: 0 5px;
}
.addHover:hover {
  opacity: 0.6;
  /* background-color: #ffff; */
   background: rgba(255, 255, 255, 0.9);
}
/* .addHover.v-btn:not(.on-hover) {
  opacity: 0.6;
  background-color: #ffff;
 } */
</style>
