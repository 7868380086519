<template>
  <v-app id="inspire">
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-fab-transition>
      <v-btn
        v-scroll="onScroll"
        v-show="fab"
        fab
        color="white"
        fixed
        width="50"
        height="50"
        bottom
        right
        @click="toTop"
      >
        <i class="fa fa-angle-up"></i>
      </v-btn>
    </v-fab-transition>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    fab: false,
  }),
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>

<style scoped>
.v-btn--fab.v-btn--contained {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
.v-btn--icon.v-size--default .v-icon,
.v-btn--fab.v-size--default .v-icon {
  height: 30px;
  font-size: 30px;
  width: 30px;
}
.v-btn--fab.v-btn--fixed {
  z-index: 9999;
}
.fa-angle-up {
  display: block;
  line-height: 48px;
  font-size: 22px;
  font-weight: 600;
  color: #ea222d;
}
.v-btn--absolute.v-btn--bottom,
.v-btn--fixed.v-btn--bottom {
  bottom: 60px;
}

.v-btn--absolute.v-btn--right,
.v-btn--fixed.v-btn--right {
  right: 20px;
}
/* @font-face {
  font-family: "PFBagueSansPro-Light";
  src: url("~@/assets/fonts/PFBagueSansPro-Light.ttf") format("truetype");
}
@font-face {
  font-family: "PFBagueSansPro-Thin";
  src: url("~@/assets/fonts/PFBagueSansPro-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "PFBagueSansPro-Bold";
  src: url("~@/assets/fonts/PFBagueSansPro-Bold.otf");
}
@font-face {
  font-family: "PFBagueSansPro-Medium";
  src: url("~@/assets/fonts/PFBagueSansPro-Medium.otf");
}
@font-face {
  font-family: "PFBagueSansPro-Black";
  src: url("~@/assets/fonts/PFBagueSansPro-Black.otf");
} */
@font-face {
  font-family: "InterstateGreek-Regular";
  src: url("~@/assets/fonts/InterstateGreek-Regular.otf");
}
@font-face {
  font-family: "PFBagueSansPro-Regular";
  src: url("~@/assets/fonts/PFBagueSansPro-RegularNew.ttf") format("truetype");
}

@font-face {
  font-family: "Manrope-Bold";
  src: url("~@/assets/fonts/Manrope-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Manrope-ExtraBold";
  src: url("~@/assets/fonts/Manrope-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "Manrope-ExtraLight";
  src: url("~@/assets/fonts/Manrope-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "Manrope-Light";
  src: url("~@/assets/fonts/Manrope-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Manrope-Medium";
  src: url("~@/assets/fonts/Manrope-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Manrope-Regular";
  src: url("~@/assets/fonts/Manrope-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Manrope-SemiBold";
  src: url("~@/assets/fonts/Manrope-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "IBMPlexSans-ExtraLight";
  src: url("~@/assets/fonts/IBMPlexSans-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "IBMPlexSans-SemiBold";
  src: url("~@/assets/fonts/IBMPlexSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto-Thin";
  src: url("~@/assets/fonts/Roboto-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-Light";
  src: url("~@/assets/fonts/Roboto-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-Regular";
  src: url("~@/assets/fonts/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-Medium";
  src: url("~@/assets/fonts/Roboto-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-Bold";
  src: url("~@/assets/fonts/Roboto-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "PFBagueSansPro-Bold";
  src: url("~@/assets/fonts/PFBagueSansPro-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "PFBagueSansPro-Bold2";
  src: url("~@/assets/fonts/pfbaguesanspro-bold.woff2") format("truetype");
}

@font-face {
  font-family: "PFBagueSansPro-Light";
  src: url("~@/assets/fonts/PFBagueSansPro-Light.ttf") format("truetype");
}
@font-face {
  font-family: "PFBagueSansPro-Light2";
  src: url("~@/assets/fonts/pf_bague_sans_pro_light.woff2") format("truetype");
}
@font-face {
  font-family: "PFBagueSansPro-Thin";
  src: url("~@/assets/fonts/pf_bague_sans_pro_thin.woff") format("truetype");
}



.v-application .title {
  font-size: 1.25rem !important;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em !important;
  font-family: "Manrope-Light" !important;
}
</style>
