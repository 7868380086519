import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
import Missing from '../views/404.vue'
import Demo from '../views/Demo.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HHG',
    meta: {
        title: 'Health_e Bonus Card'
    },
    component: () => import('../views/all-home-version/CorporateBusiness.vue')
  },
  {
    path: '/privileges',
    name: 'Privileges',
    meta: {
        title: 'Privileges'
    },
    props: true,
    component: () => import('../views/Privileges.vue')
  },
  {
    path: '/rewards',
    name: 'Rewards',
    meta: {
        title: 'Rewards'
    },
    props: true,
    component: () => import('../views/Rewards.vue')
  },
  {
    path: '/bonus-card',
    name: 'BonusCard',
    meta: {
        title: 'BonusCard'
    },
    component: () => import('../views/BonusCard.vue')
  },
  {
    path: '/terms',
    name: 'Terms',
    meta: {
        title: 'About'
    },
    component: () => import('../views/Terms.vue')
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    meta: {
        title: 'PrivacyPolicy'
    },
    component: () => import('../views/PrivacyPolicy.vue')
  },
  {
    path: '/questions',
    name: 'Questions',
    meta: {
        title: 'Questions'
    },
    component: () => import('../views/Questions.vue')
  },
  {
    path: '/information',
    name: 'Information',
    meta: {
        title: 'Information'
    },
    component: () => import('../views/Information.vue')
  },
  {
    path: '/profile',
    name: 'MainDemo',
    meta: {
        title: 'Profile'
    },
    component: () => import('../views/Profile.vue')
  },
  {
    path: '/history',
    name: 'History',
    meta: {
        title: 'History'
    },
    component: () => import('../views/History.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: {
        title: 'Contact'
    },
    component: () => import('../views/Contact.vue')
  },
  {
    path: '*',
    component: Missing,
    meta: {
        title: '404'
    },
}
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0

    if (to&&to.hash) {
      scrollTo = to.hash
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }

    return goTo(scrollTo)
  },
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    next()
 })


export default router
