import axios from 'axios'

// let baseUrl = 'https://api.roadcube.app/v1/';
let baseUrl = 'https://api.roadcube.io/v1/';
let idToken = 'gfyr73kx-mn58-0o2b-bas3-ldbbxx92mk57';

axios.defaults.baseURL = baseUrl;
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['X-Web-App-Token'] = idToken;
axios.defaults.headers.common['X-Locale'] = 'el';
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');

