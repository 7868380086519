<template>
  <div>
    <v-navigation-drawer
      class="hidden-md-and-up header-one-page-nav-drawer"
      v-model="drawer"
      fixed
      width="60vw"
    >
      <v-list-item class="pa-2">
        <v-spacer></v-spacer>
        <v-btn large icon @click="drawer = !drawer" v-html="iconSvg(closeIcon)">
        </v-btn>
      </v-list-item>
      <scrollactive
        active-class="v-btn--active"
        bezier-easing-value=".5,0,.35,1"
      >
        <v-list>
          <v-list-item :ripple="false" to="/" link class="scrollactive-item"
            ><v-list-item-content>
              <v-list-item-title>Αρχική</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :ripple="false"
            to="/privileges"
            link
            class="scrollactive-item"
            ><v-list-item-content>
              <v-list-item-title>Προνόμια</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :ripple="false"
            to="/information"
            link
            class="scrollactive-item"
            ><v-list-item-content>
              <v-list-item-title>Πληροφορίες</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :ripple="false"
            to="/questions"
            link
            class="scrollactive-item"
            ><v-list-item-content>
              <v-list-item-title>Συχνές ερωτήσεις</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :ripple="false"
            to="/contact"
            link
            class="scrollactive-item"
            ><v-list-item-content>
              <v-list-item-title>Επικοινωνία</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="!user"
            :ripple="false"
            link
            class="scrollactive-item"
            ><v-list-item-content>
              <Register fromHeaderMobile="true" title="Εγγραφή" />
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="!user"
            :ripple="false"
            link
            class="scrollactive-item"
            ><v-list-item-content>
              <Login fromHeaderMobile="true" title="Σύνδεση" />
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            :ripple="false"
            v-if="user"
            to="/profile"
            link
            class="scrollactive-item"
            ><v-list-item-content>
              <v-list-item-title>Το προφίλ μου</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :ripple="false"
            v-if="user"
            to="/history"
            link
            class="scrollactive-item"
            ><v-list-item-content>
              <v-list-item-title>Οι Συναλλαγές μου</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="user"
            :ripple="false"
            link
            @click="logout"
            href="javascript:void(0)"
            class="scrollactive-item"
          >
            <v-list-item-content>
              <v-list-item-title>Αποσύνδεση</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- End mobile menu sidebar item list -->
        </v-list>
      </scrollactive>
    </v-navigation-drawer>
    <v-app-bar
      dark
      color="transparent"
      fixed
      elevate-on-scroll
      class="header-one-page-nav"
    >
      <router-link to="/" class="logo">
        <!-- <img
          class="imgStyle"
          style="margin-left: -40px;"
          src="../../assets/images/logo/logo-all-dark.png"
          alt="Creative Agency Logo"
        /> -->
        <img
          v-if="isMobile()"
          class="imgStyle"
          style="margin-left: -40px;"
          src="../../assets/images/logo/logo-all-dark-mobile.png"
          alt="Creative Agency Logo"
        />
        <img
          v-else
          class="imgStyle"
          style="margin-left: -40px;"
          src="../../assets/images/logo/logo-all-dark.png"
          alt="Creative Agency Logo"
        />
      </router-link>
      <!-- End brand logo -->
      <v-spacer></v-spacer>
      <v-btn
        icon
        class="ma-0 pa-0 hidden-md-and-up"
        @click="drawer = !drawer"
        style="z-index=999"
        color="black"
        v-html="iconSvg(icon)"
      >
      </v-btn>
      <!-- End mobile menu icon -->
      <v-toolbar-items
        class="hidden-xs-only hidden-sm-only height-auto newTest mr--50 mr_md--0"
      >
        <scrollactive
          active-class="v-btn--active"
          bezier-easing-value=".5,0,.35,1"
        >
          <!-- <v-btn to="/" link :ripple="false" text class="scrollactive-item">
            <i class="fa fa-home"></i>
          </v-btn> -->

          <v-btn
            to="/privileges"
            link
            :ripple="false"
            text
            class="scrollactive-item"
            >Προνόμια</v-btn
          >
          <v-btn
            to="/information"
            link
            :ripple="false"
            text
            class="scrollactive-item"
            >Πληροφορίες</v-btn
          >
          <v-btn
            to="/questions"
            link
            :ripple="false"
            text
            class="scrollactive-item"
            >Συχνές ερωτήσεις</v-btn
          >
          <v-btn
            to="/contact"
            link
            :ripple="false"
            text
            class="scrollactive-item"
            >Επικοινωνία</v-btn
          >

          <Register fromHeader="true" v-if="!user" title="Εγγραφή" />
          <Login fromHeader="true" v-if="!user" title="Σύνδεση" />

          <v-menu
            v-if="user"
            open-on-hover
            bottom
            offset-y
            transition="scroll-y-reverse-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="scrollactive-item"
                :ripple="false"
                text
                v-bind="attrs"
                v-on="on"
              >
                Το προφίλ μου
              </v-btn>
            </template>

            <v-list>
              <v-list-item link :ripple="false" text to="/profile">
                <v-list-item-title>Το Προφίλ μου</v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                :ripple="false"
                class="mt--10"
                text
                to="/history"
              >
                <v-list-item-title>Οι Συναλλαγές μου</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="user"
                link
                class="mt--10"
                :ripple="false"
                text
                @click="logout"
                href="javascript:void(0)"
              >
                <v-list-item-title>Αποσύνδεση</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </scrollactive>
      </v-toolbar-items>
      <!-- End header menu item -->
    </v-app-bar>
    <!-- End top header navbar -->
  </div>
</template>

<script>
import feather from "feather-icons";
import axios from "axios";
import { mapMutations, mapActions, mapGetters } from "vuex";
import Login from "@/views/Login";
import Register from "@/views/Register";

export default {
  components: { Login, Register },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters(["token"]),
  },
  async created() {},
  methods: {
    ...mapActions(["getToken"]),
    logout() {
      console.log("tertest");
      localStorage.removeItem("token");
      this.$loading(true);
      var paths = ["/", "/home"];
      paths.includes(this.$router.currentRoute.path)
        ? this.$router.go(this.$router.currentRoute)
        : this.$router.push("/");
      this.$loading(false);
    },
    isMobile() {
      if (screen.width <= 600) {
        return true;
      } else {
        return false;
      }
    },
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
  data: () => ({
    drawer: false,
    items: [
      // { title: "Home", to: "#home" },
      { title: "Προνόμια", to: "/privileges" },
      { title: "Πληροφορίες", to: "/#about" },
      { title: "Το προφίλ μου", to: "/profile" },
      { title: "Επικοινωνία", to: "/contact" },
    ],
    brandImages: [
      {
        src: require("../../assets/images/logo/ugeia.jpg"),
        url: "#",
      },
      {
        src: require("../../assets/images/logo/metropolitan.png"),
        url: "#",
      },
      {
        src: require("../../assets/images/logo/mitera.png"),
        url: "#",
      },
      {
        src: require("../../assets/images/logo/metroGeneral.jpg"),
        url: "#",
      },
      {
        src: require("../../assets/images/logo/lito.jpg"),
        url: "#",
      },
      {
        src: require("../../assets/images/logo/creta.png"),
        url: "#",
      },
      {
        src: require("../../assets/images/logo/healthspot_logo.png"),
        url: "#",
      },
      {
        src: require("../../assets/images/service/digitalclinic_logo.svg"),
        url: "#",
      },
      {
        src: require("../../assets/images/service/hhg_logo.svg"),
        url: "#",
      },
    ],
    icon: "menu",
    closeIcon: "x",
  }),
};
</script>
<style scoped>
.scrollactive-item {
  font-family: "PFBagueSansPro-Bold";
}
.imgStyle {
  margin-left: -10px;
}
@media only screen and (max-width: 599px) {
  .logo img {
    min-width: 325px;
  }
}
@media only screen and (max-width: 320px) {
  .imgStyle {
    margin-left: -5px;
  }
}
@media only screen and (min-width: 321px) and (max-width: 599px) {
  .imgStyle {
    margin-left: 0px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 750px) {
  .imgStyle {
    margin-left: -80px;
  }
}
@media only screen and (min-width: 751px) and (max-width: 800px) {
  .imgStyle {
    margin-left: -80px;
  }
}
@media only screen and (min-width: 801px) and (max-width: 850px) {
  .imgStyle {
    margin-left: -63px;
  }
}
@media only screen and (min-width: 851px) and (max-width: 900px) {
  .imgStyle {
    margin-left: -40px;
  }
}
@media only screen and (min-width: 901px) and (max-width: 1023px) {
  .imgStyle {
    margin-left: -30px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1105px) {
  .imgStyle {
    margin-left: -33px;
  }
}
@media only screen and (min-width: 1106px) and (max-width: 1399px) {
  .imgStyle {
    margin-left: -38px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1450px) {
  .imgStyle {
    margin-left: -30px;
  }
}
</style>
