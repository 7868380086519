import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import VueParticles from "vue-particles";
import CoolLightBox from 'vue-cool-lightbox';
import VueScrollactive from 'vue-scrollactive';
import store from './store/index'
import VueLoading from 'vuejs-loading-plugin'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Vuex from 'vuex'
import VueCountryCode from "vue-country-code-select";
import './axios'
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import '@fortawesome/fontawesome-free/css/all.css'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import './assets/scss/main.scss'
import * as VueGoogleMaps from "vue2-google-maps"


Vue.config.productionTip = false
Vue.use(VueParticles)
Vue.use(CoolLightBox)
Vue.use(VueScrollactive);
Vue.use(VueCountryCode);
Vue.use(Vuex)
Vue.use(VueSweetalert2);
Vue.use(VueLoading, {
  loader: "./assets/images/logo/logo.png",
  dark: false, // default false
  text: 'Loading', // default 'Loading'
  loading: false, // default false
  backgroundColor: 'red',
}
)

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDLQPhCsA8mDPS5hk6lpmSyJZU6e8BRHn8",
    libraries: "places"
  }
});

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
